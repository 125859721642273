import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import Pagination from "src/Components/Pagination";
import { GET_VOUCHERS_BRANDS } from "src/Redux/actions/voucherBrands";
import VoucherBrandCard from "./Components/card";
import ScreenLoaderComponent from "src/Components/ScreenLoader";

const HomeLayout = () => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state.voucherBrandReducer);
  const voucherBrands = store?.voucherBrands;
  const isVoucherBrandsLoading = store?.isVoucherBrandsLoading

  // const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(GET_VOUCHERS_BRANDS());
  }, [dispatch]);

  return !isVoucherBrandsLoading ? (
    <>
      <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
        {/* <div className="p-2 w-full h-full overflow-scroll">
          <div className="flex justify-between items-center py-4">
            <div className="flex justify-start items-center space-x-6">
              <div className="w-96 ">
                <div className="rounded-lg relative w-full flex justify-between items-center">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      className="w-6 h-6"
                      fill="text-gray-500"
                      xmlns="http://www.w3.org/2000/svg"
                      height="48"
                      viewBox="0 96 960 960"
                      width="48"
                    >
                      <path d="M782 892 528 638q-29.605 27.077-69.051 41.038Q419.503 693 381 693q-91.812 0-155.406-63.337Q162 566.325 162 474.663 162 383 225.337 319.5 288.675 256 380.11 256t155.662 63.385Q600 382.769 600 474.288 600 515 585 554.5T544 622l254 253-16 17ZM381 671q83.083 0 140.042-56.5Q578 558 578 474.5t-56.958-140Q464.083 278 381 278t-140.042 56.5Q184 391 184 474.5t56.958 140Q297.917 671 381 671Z" />
                    </svg>
                  </div>
                  <input
                    type="search"
                    className="outlined border bg-gray-50 placeholder:text-gray-600  border-primary text-textSecondary text-sm rounded outline-none focus:ring-0 block w-full pl-10 p-1.5"
                    placeholder="Search Voucher Brand"
                  />
                </div>
              </div>
              <Pagination
                total={voucherBrands?.length > 0 ? 10 : voucherBrands?.length}
                currentPage={page}
              />
            </div>
          </div>
        </div> */}
        <div className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 px-4 lg:grid-cols-4 xl:gap-x-8">
          {voucherBrands.map((voucherBrand) => (
            <VoucherBrandCard
              key={voucherBrand?.uid}
              voucherBrand={voucherBrand}
            />
          ))}
        </div>
      </div>
    </>
  ) : (
    <ScreenLoaderComponent />
  );
};

export default HomeLayout;
